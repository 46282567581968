import { Link } from "@remix-run/react";
import classnames from "classnames";
import cx from "classnames";
import qs from "qs";
import { useTranslation } from "react-i18next";
import renderDigits from "~/helpers/renderDigits";

const getPaginationNumbers = ({
  skip,
  limit,
}: {
  skip: number;
  limit: number;
}) => {
  const currentPage = Math.ceil(skip / limit) + 1;

  let paginationNumbers = [
    currentPage,
    currentPage + 1,
    currentPage + 2,
    currentPage + 3,
    currentPage + 4,
  ];
  if (currentPage === 1)
    paginationNumbers = [
      currentPage,
      currentPage + 1,
      currentPage + 2,
      currentPage + 3,
      currentPage + 4,
    ];
  if (currentPage === 2)
    paginationNumbers = [
      currentPage - 1,
      currentPage,
      currentPage + 1,
      currentPage + 2,
    ];
  if (currentPage === 3)
    paginationNumbers = [
      currentPage - 1,
      currentPage,
      currentPage + 1,
      currentPage + 2,
    ];
  if (currentPage > 3)
    paginationNumbers = [
      currentPage - 2,
      currentPage - 1,
      currentPage,
      currentPage + 1,
      currentPage + 2,
    ];

  return { paginationNumbers, currentPage };
};

function Pagination({
  limit = 30,
  skip = 0,
  total,
  resource = "transactions",
  initialQs = {},
  className,
  location,
}) {
  const { t, i18n } = useTranslation();

  return (
    <div
      className={cx("flex justify-center", {
        [className]: className,
      })}
    >
      {skip - limit >= 0 && (
        <Link
          to={`${location.pathname}?${qs.stringify({ ...initialQs, skip: skip - limit, limit })}`}
          className={classnames("btn btn-sm rounded-e-none rounded-s", {
            "btn-disabled": skip - limit < 0,
          })}
        >
          {t("pagination.previous")}
        </Link>
      )}
      {getPaginationNumbers({ skip, limit }).paginationNumbers.map((number) => (
        <Link
          to={`${location.pathname}?${qs.stringify({ ...initialQs, skip: limit * (number - 1), limit })}`}
          className={classnames("btn btn-sm rounded-none", {
            "btn-active":
              getPaginationNumbers({ skip, limit }).currentPage === number,
            "btn-disabled": limit * (number - 1) >= Number(total),
          })}
          key={number * limit + limit}
        >
          {renderDigits({ data: number, i18n })}
        </Link>
      ))}
      <Link
        to={`${location.pathname}?${qs.stringify({ ...initialQs, skip: skip + limit, limit })}`}
        className={classnames("btn btn-sm rounded-e rounded-s-none", {
          "btn-disabled": skip + limit >= Number(total),
        })}
      >
        {t("pagination.next")}
      </Link>
    </div>
  );
}

export default Pagination;
